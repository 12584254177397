<template>
  <h1
    class="p-card p-shadow-2 p-text-bold p-p-3 p-text-center p-text-uppercase"
  >
    Pour commencer
  </h1>
  <div class="p-grid p-mt-4">
    <div class="p-col-12 p-md-6 p-p-3">
      <h3 class="p-mt-6">Ouvrir un compte</h3>
      <p style="font-size: 1.2em; line-height: 1.5em">
        A la création du compte, on vous demande quelques informations. Veuillez
        bien renseigner le formulaire. Pour votre nouveau compte, vous
        profiterez <mark>30 jours d'essais</mark>. Vous aurez le temps de vous
        adapter à la platforme. C'est votre opportunité de travailler en mode
        d'entraînement sans forcement acheter un abonnment. Pour verifier que
        vous utilisez une addresse e-mail exacte, un code vous sera envoyé par
        cette addresse. On vous demandera de saisir pour enfin ouvrir votre
        compte.
      </p>
    </div>
    <div class="p-col-12 p-md-6">
      <div
        :style="`background-image: url('/registerForm.PNG')`"
        class="p-shadow-4 p-card image"
      />
    </div>
  </div>

  <Message class="q-my-5" severity="info" :closable="false">
    Quand vous étiez invité par email d'autre utilisateur pour joindre son
    activité, vous n'aurez plus à qu'à vous connecter. Vous aurez les
    informations de connexion. Un utilisateur peut donc inviter d'autres
    utilisateurs pour être un responsable au niveau de son activité.
  </Message>

  <div class="p-grid">
    <div class="p-col-12 p-md-6">
      <div
        :style="`background-image: url('/login.PNG')`"
        class="p-shadow-4 p-card image"
      />
    </div>
    <div class="p-col-12 p-md-6 p-p-3">
      <h3 class="p-mt-6">Se connecter à votre compte</h3>
      <p style="font-size: 1.2em; line-height: 1.5em">
        Pour commencer à travailler avec votre compte, vous devez vous connecter
        à l'aide d'un identifiant(votre addresse email) et d'un mot de passe.
        Lorsque vous oubliez votre mot de passe, vous pouvez encore récupérer
        votre compte en cliquant sur « Mot de passe oublié ? » Une fois que vos
        informations sont entrées correctement, vous pouvez cliquez sur « Se
        connecter » pour être redirigé dans votre espace de travail.
      </p>
    </div>
  </div>

  <h3 class="p-mt-6">Lors de la récupération de votre compte</h3>
  <div
    :style="`background-image: url('/recuperation.PNG');`"
    class="image recuperation"
  />

  <div class="p-d-flex p-justify-center p-mt-5">
    <Button
      style="width: 50%"
      label="Apprendre plus"
      class="p-button-warning p-py-3 p-button-rounded p-shadow-2"
      @click="$router.push('/documentation/account')"
    />
  </div>
</template>

<script>
export default {
  name: "DocHome",
};
</script>

<style lang="scss" scoped>
.image {
  background-size: cover;
  background-repeat: no-repeat;
  height: 50vh;
  margin-top: 20px;
  border-radius: 20px;
}
.recuperation {
  height: 80vh;
}
</style>
